import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react'
import { getStore } from 'utils'
import { HomepageStore } from 'stores/home'
import { THEME } from 'utils'
import ReviewCard from 'components/ReviewCard'
import VenueCard from 'components/VenueCard'
import Rating from 'components/Rating'
import ProductCarousel from 'components/ProductCarousel'
import BinocularsIcon from 'icons/Binoculars'
import WatchIcon from 'icons/Watch'
import PiesIcon from 'icons/Pies'
import ArrowIcon from 'icons/Arrow'
import MediaQuery from 'react-responsive'

const Home = () => {
  const homepageStore = getStore(HomepageStore)

  return (
    <>
      <section className='section section--hero'>
        <div className='container'>
          <h1 className='hero__title'>The best reviews of the best pizza.</h1>
          <p className='hero__subtitle'>How it works:</p>
          <div className='hero__icons'>
            <div className='hero__icon'>
              <BinocularsIcon color={THEME.COLORS.PRIMARY} width='auto' height='36px' />
              <p className='hero__iconTitle'>Find Pizza</p>
            </div>
            <div className='hero__icon'>
              <WatchIcon color={THEME.COLORS.PRIMARY} width='auto' height='48px' />
              <p className='hero__iconTitle'>Watch Reviews</p>
            </div>
            <div className='hero__icon'>
              <PiesIcon color={THEME.COLORS.PRIMARY} width='auto' height='52px' />
              <p className='hero__iconTitle'>Order Delivery</p>
            </div>
            <div className='hero__icon'>
              <Rating size='55' score={8.4} />
              <p className='hero__iconTitle'>Leave a Review</p>
            </div>
          </div>
        </div>
      </section>
      <section className='section'>
        <div className='container'>
          {homepageStore.pizzaProducts.length ? (
            <ProductCarousel
              title='Shop Frozen Pizzas'
              items={homepageStore.pizzaProducts}
              link='https://store.barstoolsports.com/collections/one-bite-frozen-pizza'
            />
          ) : null}
          <MediaQuery minWidth={THEME.BREAKPOINTS.SM}>
            <br />
          </MediaQuery>
          {homepageStore.merchProducts.length ? (
            <ProductCarousel
              title='Shop One Bite Merch'
              items={homepageStore.merchProducts}
              link='https://store.barstoolsports.com/collections/one-bite'
            />
          ) : null}
        </div>
      </section>
      <section className='section section--gray section--dave'>
        <div className='container'>
          <h2 className='section__title'>Dave's Reviews</h2>
          <h3 className='section__subtitle'>
            Watch the latest of David "El Pres" Portnoy's {homepageStore.daveReviews[0].user.reviewStats.count} reviews.
          </h3>
          <div className='row'>
            <div className='col'>
              <ReviewCard review={homepageStore.daveReviews[0]} featured isDave />
            </div>
            <div className='col'>
              <div className='row'>
                {homepageStore.daveReviews.slice(1, 5).map((review) => (
                  <div className='col col--half' key={review.id}>
                    <ReviewCard review={review} isDave />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='text-center'>
            <Link href='/reviews/dave' as='/reviews/dave'>
              <a className='btn btn--primary btn--iconRight'>
                More reviews from Dave
                <ArrowIcon direction='right' />
              </a>
            </Link>
          </div>
        </div>
      </section>
      <section className='section section--cities'>
        <div className='container'>
          <h2 className='section__title'>Discover Restaurants</h2>
          <h3 className='section__subtitle'>Find the most popular restaurants from our One Bite users.</h3>
        </div>
        <div className='container'>
          <div className='carousel'>
            {homepageStore.venues.map((venue) => {
              return (
                <Link key={venue.id} href='/restaurant/[restaurantSlug]' as={`/restaurant/${venue.slug}`}>
                  <a className='carousel__item'>
                    <VenueCard venue={venue} />
                  </a>
                </Link>
              )
            })}
          </div>
          <div className='text-center'>
            <Link href='/restaurant/fan-favorites' as='/restaurant/fan-favorites'>
              <a className='btn btn--primary btn--iconRight'>
                More Restaurants
                <ArrowIcon direction='right' />
              </a>
            </Link>
          </div>
        </div>
      </section>
      <section className='section section--reviews'>
        <div className='container'>
          <h2 className='section__title'>Explore Reviews</h2>
          <h3 className='section__subtitle'>Celebrities: Watch celebrities give a One Bite review with Dave.</h3>
          <div className='row'>
            {homepageStore.celebrityReviews.slice(0, 2).map((review) => (
              <div className='col col--half' key={review.id}>
                <ReviewCard review={review} featured />
              </div>
            ))}
          </div>
          <h3 className='section__subtitle'>
            Fans: Find out about great pizza near you as other give their One Bite reviews.
          </h3>
          <div className='carousel'>
            {homepageStore.fanReviews.map((review) => (
              <div className='carousel__item' key={review.id}>
                <ReviewCard review={review} />
              </div>
            ))}
          </div>
          <div className='text-center'>
            <Link href='/reviews/fans' as='/reviews/fans'>
              <a className='btn btn--primary btn--iconRight'>
                Discover more
                <ArrowIcon direction='right' />
              </a>
            </Link>
          </div>
        </div>
      </section>
      <section className='section section--gray section--download'>
        <div className='container'>
          <div className='row'>
            <div className='col col--half'>
              <h2 className='section__title'>One Bite, Everybody Knows The Rules.</h2>
              <img
                className='downloadScreens downloadScreens--sm'
                src='/static/images/screens.jpg'
                alt='One Bite Pizza Review App'
              />
              <p className='section__description'>
                Find the best slice of pizza using the One Bite app from Barstool Sports. Starting as a video series,
                Dave “El Presidente” Portnoy has created a cult following of people giving “one bite” pizza reviews by
                the common man, for the common man. Now you can find all of Dave’s reviews complete with videos, scores
                and location here. One Bite users can upload their own reviews at any of the 125,000+ pizza restaurants
                listed across the world.
              </p>
              <div className='downloadButtons'>
                <a href='https://apps.apple.com/app/apple-store/id1356660515' target='_blank' rel='noopener noreferrer'>
                  <img src='/static/images/badge-apple-store.svg' alt='Download One Bite App for iPhone' />
                </a>
                <a
                  href='https://play.google.com/store/apps/details?id=com.barstoolsports.onebite&utm_source=onebite.app&utm_campaign=homepage&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <img src='/static/images/badge-google-play-store.svg' alt='Download One Bite App for Android' />
                </a>
              </div>
            </div>
            <div className='col col--half'>
              <img
                className='downloadScreens downloadScreens--lg'
                src='/static/images/screens.jpg'
                alt='One Bite Pizza Review App'
              />
            </div>
          </div>
        </div>
      </section>
      <style jsx>{`
        .col--half {
          min-width: 50%;
          flex-basis: 50%;
        }
        .section {
          background: #fff;
          padding: 30px 0;
        }
        .section__nav {
          border-bottom: 1px solid #e8e8e8;
          padding-bottom: 20px;
          margin-bottom: 20px;
        }
        .section__nav .container {
          overflow-x: auto;
        }
        .section__navItem {
          color: #757575;
          font-size: 14px;
          letter-spacing: 0.1px;
          line-height: 20px;
          margin-left: 50px;
          white-space: nowrap;
        }
        .section__navItem:first-child {
          margin-left: 0;
        }
        .section--hero {
          padding: 100px 0 0;
          position: relative;
          background-color: #fdf6e2;
          background-image: url(/static/images/hero-left.png), url(/static/images/hero-right.png);
          background-position: left bottom, right top;
          background-repeat: no-repeat;
          background-size: 150px 127.5px, 152.5px 142.5px;
          color: #000;
        }
        .section--gray {
          background: #f9f9f9;
        }
        .section--dave .col,
        .section--download .col,
        .section--reviews .col--half {
          min-width: 100%;
          flex-basis: 100%;
        }
        .section--dave .col--half {
          min-width: 50%;
          flex-basis: 50%;
        }
        .section--cities {
          border-bottom: 1px solid #e8e8e8;
        }
        .hero__title {
          max-width: 520px;
          margin: 0 auto;
          font-size: 34px;
          font-weight: bold;
          letter-spacing: -0.8px;
          line-height: 37px;
          text-align: center;
        }
        .hero__subtitle {
          font-size: 22px;
          font-weight: 600;
          letter-spacing: 0.35px;
          line-height: 28px;
          text-align: center;
          margin: 25px 0;
        }
        .hero__icons {
          display: table;
          margin: auto;
        }
        .hero__icon {
          display: inline-flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          margin: 0 0 30px;
          width: 50%;
          vertical-align: bottom;
        }
        .hero__icon .svgIcon {
          display: inline-block;
        }
        .hero__iconTitle {
          text-align: center;
          color: #333;
          font-weight: 500;
          font-size: 14px;
          font-weight: bold;
          line-height: 17px;
          margin: 15px 0 0;
        }
        .section__title {
          color: ${THEME.COLORS.PRIMARY};
          font-size: 22px;
          font-weight: bold;
          letter-spacing: -0.46px;
          line-height: 33px;
          margin: 0 0 20px;
        }
        .section__subtitle {
          color: #222;
          font-size: 20px;
          font-weight: bold;
          line-height: 25px;
          margin: 0 0 15px;
        }
        .section__title + .section__subtitle {
          margin-top: -15px;
        }
        .section--download .section__title {
          color: #000;
          font-size: 38px;
          font-weight: bold;
          letter-spacing: -0.54px;
          line-height: 45px;
          margin: 0 0 20px;
          text-align: center;
        }
        .section--download .section__description {
          color: #222;
          font-size: 16px;
          letter-spacing: 0.11px;
          line-height: 25px;
          margin: 0;
          text-align: center;
        }
        .downloadButtons {
          margin-top: 35px;
          text-align: center;
        }
        .downloadButtons img {
          display: inline-block;
          height: 50px;
          width: auto;
          margin-left: 5px;
          margin-right: 5px;
        }
        .downloadScreens {
          display: block;
          width: 100%;
          max-width: 250px;
          margin: 20px auto;
        }
        .downloadScreens--lg {
          display: none;
        }
        .carousel {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          margin: 0 -7.5px 30px;
        }
        .carousel__item {
          display: block;
          padding: 0 7.5px;
        }
        .section--reviews .carousel__item {
          min-width: 320px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}) {
          .section {
            padding: 40px 0;
          }
          .section--download .col,
          .section--reviews .col,
          .section--dave .col--half,
          .section--dave .col {
            min-width: 50%;
            flex-basis: 50%;
          }
          .section__title {
            font-size: 32px;
            line-height: 38px;
            margin: 0 0 30px;
          }
          .section--hero {
            padding: 110px 0 50px;
            background-size: 300px 255px, 305px 285px;
          }
          .section--download .row {
            align-items: center;
          }
          .section--download .section__title,
          .section--download .section__description {
            text-align: left;
          }
          .downloadButtons {
            margin-top: 65px;
            text-align: left;
          }
          .downloadButtons img {
            margin-left: 0;
            margin-right: 20px;
          }
          .downloadScreens {
            margin: 0;
            margin-left: auto;
            max-width: 475px;
          }
          .downloadScreens--sm {
            display: none;
          }
          .downloadScreens--lg {
            display: block;
          }
          .hero__title {
            font-size: 56px;
            line-height: 64px;
          }
          .hero__subtitle {
            margin: 55px 0;
          }
          .hero__icon {
            margin: 0 30px;
            width: auto;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.MD}) {
          .hero__icon {
            margin: 0 60px;
          }
        }
      `}</style>
    </>
  )
}

export default observer(Home)
