import React from 'react'
import RatingIcon from 'components/Rating'

const VenueCard = ({ venue }) => {
  return (
    <>
      <div className='venueCard' style={{ backgroundImage: `url(${venue.imageUrl})` }}>
        <div className='venueCard__overlay' />
        <div className='venueCard__info'>
          <h2 className='venueCard__title'>{venue.name}</h2>
          <p className='venueCard__address'>{venue.address1}</p>
        </div>
        <div className='venueCard__rating'>
          {venue.reviewStats.dave.count ? (
            <RatingIcon score={venue.reviewStats.dave.averageScore} isDave size='60' />
          ) : null}
          <div style={{ width: '2px' }} />
          {venue.reviewStats.all.count ? <RatingIcon score={venue.reviewStats.all.averageScore} size='60' /> : null}
        </div>
      </div>
      <style jsx>{`
        .venueCard {
          background-size: cover;
          width: 320px;
          height: 250px;
          position: relative;
          overflow-y: visible;
        }
        .venueCard__overlay {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
        }
        .venueCard__info {
          position: absolute;
          top: 0;
          left: 0;
          padding: 12px 15px;
          width: 100%;
          z-index: 1;
        }
        .venueCard__title {
          color: #fff;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: -0.1px;
          line-height: 30px;
          margin: 0;
        }
        .venueCard__address {
          color: #aaa;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 0.1px;
          line-height: 20px;
          margin: 0;
        }
        .venueCard__rating {
          position: absolute;
          display: flex;
          right: 10px;
          bottom: 10px;
          z-index: 1;
        }
      `}</style>
    </>
  )
}

export default VenueCard
