import React from 'react'

export default function WatchIcon({ color = '#222222', width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 49 49' className='svgIcon socialIcon iconWatch' style={{ width, height }}>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-487.000000, -479.000000)'>
          <g>
            <g transform='translate(263.000000, 385.000000)'>
              <g transform='translate(0.000000, 83.500000)'>
                <g transform='translate(197.000000, 11.000000)'>
                  <g transform='translate(27.000000, 0.000000)'>
                    <path
                      d='M5.20702039,4 C4.55261174,4 4,4.53044648 4,5.15806259 L4,27.8419374 C4,28.4698027 4.55261174,29 5.20702039,29 L43.7929796,29 C44.4471286,29 45,28.4698027 45,27.8419374 L45,5.15806259 C45,4.53044648 44.4471286,4 43.7929796,4 L5.20702039,4 Z M43.8757897,33 L5.12421028,33 C2.29869653,33 0,30.7684364 0,28.0254412 L0,4.97455885 C0,2.2315636 2.29869653,0 5.12421028,0 L43.8757897,0 C46.7013035,0 49,2.2315636 49,4.97455885 L49,28.0254412 C49,30.7684364 46.7013035,33 43.8757897,33 L43.8757897,33 Z'
                      fill={color}
                    />
                    <polygon fill={color} points='20 16 20 9 25.5001203 12.5 31 16 25.5001203 19.5 20 23' />
                    <path
                      d='M14,42.9998786 C14,44.6567671 12.6568215,46 11,46 C9.34317851,46 8,44.6567671 8,42.9998786 C8,41.3432329 9.34317851,40 11,40 C12.6568215,40 14,41.3432329 14,42.9998786'
                      fill={color}
                    />
                    <path
                      d='M39,42.9998786 C39,44.6567671 37.6568215,46 36.0002428,46 C34.3431785,46 33,44.6567671 33,42.9998786 C33,41.3432329 34.3431785,40 36.0002428,40 C37.6568215,40 39,41.3432329 39,42.9998786'
                      fill={color}
                    />
                    <g transform='translate(20.000000, 39.000000)'>
                      <mask id='mask-2' fill='white'>
                        <polygon points='0.013475 0.074875 8.938725 0.074875 8.938725 9 0.013475 9' />
                      </mask>
                      <path
                        d='M8.938725,4.537375 C8.938725,7.001875 6.940725,9.000125 4.475975,9.000125 C2.011725,9.000125 0.013475,7.001875 0.013475,4.537375 C0.013475,2.072875 2.011725,0.074875 4.475975,0.074875 C6.940725,0.074875 8.938725,2.072875 8.938725,4.537375'
                        id='Fill-9'
                        fill={color}
                        mask='url(#mask-2)'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
