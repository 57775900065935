import React from 'react'
import dynamic from 'next/dynamic'
import { useMediaQuery } from 'react-responsive'
import { THEME } from 'utils'
import ArrowIcon from 'icons/Arrow'
import StoreProduct from 'components/StoreProduct'
import MediaQuery from 'react-responsive'
const Slider = dynamic(() => import('react-slick'), { ssr: false })

const NextArrow = ({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <ArrowIcon direction='right' width='auto' height='24px' color='#000' />
  </div>
)
const PrevArrow = ({ className, style, onClick }) => (
  <div className={className} style={style} onClick={onClick}>
    <ArrowIcon direction='left' width='auto' height='24px' color='#000' />
  </div>
)

const LastSlide = ({ link }) => (
  <div className='col col--item'>
    <a href={link} className='lastSlide'>
      <img src='static/images/logo.png' alt='last_img' />
      <MediaQuery minWidth={1024}>
        <button className='btn btn--primary btn--iconRight'>
          Shop the full collection
          <ArrowIcon direction='right' />
        </button>
      </MediaQuery>
      <MediaQuery maxWidth={1023}>
        <button className='btn btn--primary'>Shop the full collection</button>
      </MediaQuery>
    </a>
    <style jsx>{`
      .col--item {
        flex-basis: 100%;
        min-width: 100%;
        max-width: 100%;
      }
      .lastSlide {
        display: block;
        border-radius: 6px;
        box-shadow: 0 0 8px 3px rgb(203 203 203 / 50%);
        position: relative;
        background-color: #fdf6e2;
        width: 100%;
        padding-top: 100%;
      }
      .lastSlide img {
        position: absolute;
        top: 35%;
        left: 0;
        right: 0;
        display: block;
        width: 55%;
        margin: 0 auto;
      }
      .lastSlide button {
        background: transparent;
        position: absolute;
        bottom: 10%;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 80%;
        padding: 12px;
      }
      @media (max-width: ${THEME.BREAKPOINTS.LG}) {
        .lastSlide img {
          width: 60%;
        }
        .lastSlide button {
          bottom: 10%;
          width: 90%;
          font-size: 11px;
        }
      }
      @media (max-width: ${THEME.BREAKPOINTS.SM}) {
        .col--item {
          flex-basis: 220px;
          min-width: 220px;
          max-width: 220px;
        }
        .lastSlide img {
          width: 60%;
        }
        .lastSlide button {
          bottom: 10%;
          width: 90%;
          padding: 8px;
        }
      }
    `}</style>
  </div>
)

const ProductCarousel = ({ title, items, link }) => {
  const shouldSlick = useMediaQuery({ query: '(min-width: 768px)' })
  const settings = {
    className: 'productCarousel__slider',
    dots: false,
    infinite: false,
    lazyLoad: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToShow: 4,
    slidesToScroll: 4
  }
  if (!items || !items.length) return null
  const slides = items.slice(0, 15).map((item) => {
    return (
      <div key={item.id} className='col col--item'>
        <StoreProduct product={item} />
        <style jsx>{`
          .col--item {
            flex-basis: 220px;
            min-width: 220px;
            max-width: 220px;
            padding-left: 15px !important;
            padding-right: 15px !important;
          }
        `}</style>
      </div>
    )
  })
  return (
    <>
      <div className='productCarousel'>
        {link && (
          <div className='productCarousel__header'>
            {title && <h2 className='productCarousel__title'>{title}</h2>}
            <a href={link} target='_blank' rel='noreferrer'>
              View all
            </a>
          </div>
        )}
        <div className='productCarousel__body'>
          {shouldSlick ? (
            <Slider {...settings}>
              {slides}
              <LastSlide link={link} key='lastSlide' />
            </Slider>
          ) : (
            <div className='row row--scroll'>
              {slides}
              <LastSlide link={link} key='lastSlide' />
            </div>
          )}
        </div>
      </div>
      <style jsx global>{`
        .productCarousel__slider {
          padding-bottom: 10px;
        }
        .productCarousel__slider.regular {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: auto;
          justify-content: left;
          padding-bottom: 0;
          margin-left: -8px;
          margin-right: -8px;
        }
        .productCarousel .slick-arrow {
          top: 40%;
        }
        .productCarousel .slick-arrow.slick-disabled {
          opacity: 0;
        }
        .productCarousel .productCarousel__dots {
          margin: 0;
          padding: 0;
          text-align: right;
          bottom: 0px;
          right: 15px;
        }
        .productCarousel .productCarousel__dots li {
          width: 5px;
          height: 5px;
          margin: 0 5px;
        }
        .productCarousel .productCarousel__dots li button {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          margin: 0;
          padding: 0;
          background: #d8d8d8;
        }
        .productCarousel .productCarousel__dots li.slick-active button {
          background: #000;
        }
        .productCarousel .productCarousel__dots li button:before {
          content: none;
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}) {
          .productCarousel__slider .col--item {
            flex-basis: 100% !important;
            min-width: 100% !important;
            max-width: 100% !important;
          }
        }
      `}</style>
      <style jsx>{`
        .productCarousel + .productCarousel {
          margin-top: 16px;
        }
        .row {
          margin-left: -8px;
          margin-right: -8px;
        }
        .col--item {
          flex-basis: 220px;
          min-width: 220px;
          max-width: 220px;
        }
        .productCarousel__header {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .productCarousel__title {
          color: ${THEME.COLORS.PRIMARY};
          font-weight: bold;
          letter-spacing: -0.46px;
          font-size: 22px;
          margin: 0;
        }
        .productCarousel__header a {
          font-size: 14px;
          line-height: 24px;
          color: #000;
          text-transform: uppercase;
          text-decoration: underline;
        }
        .productCarousel__body {
          padding-top: 12px;
          padding-bottom: 12px;
        }
        @media (min-width: ${THEME.BREAKPOINTS.XS}) {
          .productCarousel__body {
            padding-left: 0;
            padding-right: 0;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.SM}) {
          .row {
            margin-left: -15px;
            margin-right: -15px;
          }
          .col--item {
            flex-basis: 100%;
            min-width: 100%;
            max-width: 100%;
          }
          .productCarousel__title {
            font-size: 32px;
            line-height: 38px;
          }
          .productCarousel__body {
            margin-left: -15px;
            margin-right: -15px;
          }
        }
        @media (min-width: ${THEME.BREAKPOINTS.LG}) {
          .productCarousel__body {
            height: 415px;
          }
        }
      `}</style>
    </>
  )
}

export default ProductCarousel
