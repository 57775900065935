import { observable } from 'mobx'
import { CommonStore } from 'stores/common'

export const HomepageStore = () => {
  return observable({
    ...CommonStore,
    venues: [],
    daveReviews: [],
    celebrityReviews: [],
    fanReviews: []
  })
}

HomepageStore.cacheKey = 'HomepageStore'

export default HomepageStore
