import React from 'react'

const StoreProduct = ({ product }) => {
  product = product?.node ?? product
  const price = product?.price?.amount ?? product?.price

  return (
    <>
      <a
        className='storeProduct'
        href={`https://store.barstoolsports.com/products/${product.handle}`}
        target='_blank'
        rel='noreferrer'
      >
        <div className='storeProduct__image'>
          <img src={product.images[0].originalSrc} alt={product.title} />
        </div>
        <h3 className='storeProduct__title'>{product.title}</h3>
        <div className='storeProduct__price'>
          {`$${Number(price).toFixed(2)} `}
          <span>USD</span>
        </div>
      </a>
      <style jsx>{`
        .storeProduct {
          position: relative;
        }
        .storeProduct__image {
          width: 100%;
          padding-bottom: 100%;
          position: relative;
          overflow: hidden;
          border-radius: 6px;
          box-shadow: 0 0 8px 3px rgba(203, 203, 203, 0.5);
        }
        .storeProduct__promo {
          position: absolute;
          z-index: 1;
          left: -10px;
          top: -15px;
          font-family: 'Bebas Neue';
          padding: 4px 24px;
          font-size: 20px;
          font-weight: bold;
          letter-spacing: 0.14px;
          line-height: 24px;
          border-radius: 5.5px;
          text-transform: uppercase;
        }
        .storeProduct__promo--charity {
          color: #ffffff;
          background-color: #2280c6;
        }
        .storeProduct__image img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
        }
        .storeProduct__title {
          text-align: center;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 19px;
          text-transform: uppercase;
          color: #000;
          margin: 20px 0 0;
        }
        .storeProduct__price {
          text-align: center;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 18px;
          color: #000;
          margin-top: 10px;
        }
        .storeProduct__price span {
          display: inline-block;
          font-size: 12px;
        }
        .storeProduct__colors {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 8px;
        }
        .storeProduct__color {
          flex-shrink: 1;
          border: 1px solid #ddd;
          padding: 2px;
          margin: 0 6px;
        }
        .storeProduct__colors span {
          display: block;
          width: 16px;
          height: 16px;
        }
      `}</style>
    </>
  )
}

export default StoreProduct
