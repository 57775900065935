import React from 'react'

export default function BinocularsIcon({ color = '#222222', width = '18px', height = '18px' }) {
  return (
    <svg viewBox='0 0 66 36' className='svgIcon socialIcon iconBinoculars' style={{ width, height }}>
      <title>Binoculars</title>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g transform='translate(-268.000000, -482.000000)'>
          <g>
            <g transform='translate(263.000000, 385.000000)'>
              <g transform='translate(0.000000, 83.500000)'>
                <g transform='translate(0.000000, 13.000000)'>
                  <g transform='translate(4.500000, 0.500000)'>
                    <g transform='translate(0.500000, 0.500000)'>
                      <mask id='mask-binoculars' fill='white'>
                        <polygon points='0 0 66 0 66 35 0 35' />
                      </mask>
                      <path
                        d='M50.4150994,30.6564012 C43.9692002,30.6564012 38.7251362,25.4272283 38.7251362,18.9996331 C38.7251362,12.5721984 43.9692002,7.34270449 50.4150994,7.34270449 C56.8603547,7.34270449 62.1049017,12.5721984 62.1049017,18.9996331 C62.1049017,25.4272283 56.8609986,30.6564012 50.4150994,30.6564012 M37.7918064,8.85461717 L28.2051351,8.85461717 C27.0673678,8.85461717 26.1425697,7.74335573 26.1425697,6.37960054 C26.1425697,5.01616639 27.0673678,3.90474443 28.2051351,3.90474443 L37.7918064,3.90474443 C38.9316664,3.90474443 39.8567864,5.01616639 39.8567864,6.37960054 C39.8567864,7.74319521 38.9316664,8.85461717 37.7918064,8.85461717 M16.042068,30.6564012 C9.59649076,30.6564012 4.35242669,25.4272283 4.35242669,18.9996331 C4.35242669,12.5721984 9.59632978,7.34270449 16.042068,7.34270449 C22.4879672,7.34270449 27.7320312,12.5721984 27.7320312,18.9996331 C27.7320312,25.4272283 22.4879672,30.6564012 16.042068,30.6564012 M49.9537467,2.99958724 L43.3892093,2.99958724 C41.3031417,1.1424018 37.37279,0 32.9995976,0 C28.6265661,0 24.6962144,1.14224128 22.6101468,2.99958724 L16.0457704,2.99958724 C7.19797172,2.99958724 0,10.1771194 0,18.9996331 C0,27.8219863 7.19797172,35 16.0457704,35 C24.8932471,35 32.0912188,27.8219863 32.0912188,18.9996331 C32.0912188,18.8827765 32.090092,18.7660804 32.0875164,18.6489027 L33.9121616,18.6489027 C33.909586,18.7659199 33.9082982,18.8827765 33.9082982,18.9996331 C33.9082982,27.8219863 41.1069139,35 49.9543906,35 C58.8020283,35 66,27.8219863 66,18.9996331 C66,10.1771194 58.8013844,2.99958724 49.9537467,2.99958724'
                        fill={color}
                        mask='url(#mask-binoculars)'
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
