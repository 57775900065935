import React from 'react'
import Head from 'components/Head'
import SiteLayout from 'components/SiteLayout'
import Home from 'components/Home'
import { HomepageStore } from 'stores/home'
import { ReviewsStore } from 'stores/reviews'
import { StoreStore } from 'stores/store'
import { VenuesStore } from 'stores/venues'
import { getStore, cleanProductData } from 'utils'

const HomePage = ({ venues, daveReviews, celebrityReviews, fanReviews, pizzaProducts, merchProducts }) => {
  const homepageStore = getStore(HomepageStore)
  homepageStore.hydrate({
    venues,
    daveReviews,
    celebrityReviews,
    fanReviews,
    pizzaProducts: cleanProductData(pizzaProducts),
    merchProducts: cleanProductData(merchProducts)
  })

  return (
    <>
      <SiteLayout background='#FFFFFF' transparentHeader home>
        <Head
          title='Best Pizza Near Me | Best Pizza Restaurant | One Bite'
          description='A list of the best pizza restaurants nearby whether you are in NYC, Chicago or anywhere else. Get the best pizza near you today and read & watch reviews for each pizza restaurant. '
          url={`${process.env.SITE_URL}`}
        />
        <Home />
      </SiteLayout>
    </>
  )
}

export async function getStaticProps() {
  const [
    daveReviews,
    celebrityReviews,
    fanReviews,
    venues,
    { items: pizzaProducts },
    { items: merchProducts }
  ] = await Promise.all([
    ReviewsStore().findDaveReviews({ limit: 5 }),
    ReviewsStore().find({ userType: 'CRITIC', limit: 2 }),
    ReviewsStore().find({ userType: 'REGULAR', limit: 8 }),
    VenuesStore().find({ limit: 8 }),
    StoreStore().findProductsByCollection({ collection: 'One Bite Frozen Pizza', numProducts: 16 }),
    StoreStore().findProductsByCollection({ collection: 'One Bite', numProducts: 20 })
  ])

  return {
    props: {
      daveReviews,
      celebrityReviews,
      fanReviews,
      venues,
      pizzaProducts,
      merchProducts: { edges: merchProducts.edges.filter(({ node }) => !node.tags.includes('Frozen Pizza')) }
    },
    revalidate: 60
  }
}

export default HomePage
